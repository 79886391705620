<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body" v-loading="is_loading">
			<div class="center">
				<div class="home_main">
					<div class="search_wrap">
						<div class="search_box">
							<i class="iconfont el-icon-search"></i>
							<input class="in" placeholder="点击输入框粘贴截图" @paste="handlePaste"/>
							<i class="iconfont icon-xiangji"><input class="image-uploader" type="file" accept="image/*" capture="camera" @change="onFileChange"></i>
						</div>
					</div>
					<img class="search-image" :src="imageUrl"  v-if="imageUrl" alt="">
					<div class="product_section" >
						<div class="screen_wrap" v-if="imageUrl">
							
							<p class="title" >相似款商品共<span>{{page_data.total}}</span>件</p>
							<!-- <p class="in_btn" @click="allPuhuo()">一键加入</p> -->
						</div>
						<productList v-if="imageUrl" :listData="list" :loading="product_loading"></productList>
						<el-empty v-if="!imageUrl" description="请点击输入框上传图片搜索吧~"></el-empty>
						
						<div class="turn_page">
							<Pagination :total="page_data.total" :page.sync="page_data.page" :limit.sync="page_data.limit" @pagination="getProduct" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[20,50,100]" />
						</div>
						
					</div>
				</div>
			</div>
		</div>
		<myFoot :showBeian="true"></myFoot>
	</div>
</template>

<script>
import Vue from 'vue';
import "swiper/swiper-bundle.min.css";
import { mapActions,mapState,mapMutations } from "vuex";
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import productList from '../../components/productList/productlist';
import common from '../../assets/js/utils/common';
import headDefault from '../../assets/images/head.png';
import Pagination from '../../components/pagination/pagination';

export default {
	name: "index",
	data(){
		return{
			uploadAction:"",
			headDefault,
			input1:'',
			input2:'',
			login_type:1,
			reg_step:1,
			list:[],
			userId:"",
			loadingCode:false,
			userinfo:"",
			avatar:"",
			is_loading: false,
			product_loading:false,
			currGroupId: 0,
			searchParams: {},
			page_data: {
				limit: 20,
				page: 1,
				total:0,
			},
			imageUrl:''
			
		}
	},
	components:{
		myHead,myFoot,productList,Pagination
	},
	mounted(){
		// this.$nextTick(function () {
		// 	window.addEventListener('scroll', this.onScroll)
		// })
	},
	computed:{
		...mapState({
			users:(state)=>state.login.userinfo,
			avatars:(state)=>state.login.avatar,
			ucmenu:(state)=>state.login.ucmenu,
		}),
	},
	created() {
		this.userinfo = this.users?this.users:""
		this.avatar = this.avatars || headDefault
		var query = this.$route.query
		if(query){
			this.searchParams = query
		}
		this.init()
	},
	destroyed() {
		 // window.removeEventListener('scroll', this.onScroll)
	},
	methods:{
		...mapActions({
			doAddAllProduct:"cart/doAddAllProduct",
			doSearchProductByImage:"index/doSearchProductByImage",
			getBusinessOrderCount:"index/getBusinessOrderCount",
			getCodeData:"login/getCodeData",
			getQrcodeData:"login/getQrcodeData",
			checkWxBind:"login/checkWxBind",
			DoAccountLogin:"login/DoAccountLogin",
			DoCodeLogin:"login/DoCodeLogin",
			DoRegister:"login/DoRegister",
			getUcenterMenu:"login/getUcenterMenu",
		}),
		...mapMutations({
			OUT_LOGIN:"login/OUT_LOGIN"
		}),
		handlePaste(event) {
			const items = (event.clipboardData || window.clipboardData).items;
			for (let i = 0; i < items.length; i++) {
				if (items[i].kind === 'file') {
					const file = items[i].getAsFile();
					if (file.type.startsWith('image/')) {
						// 创建一个FileReader对象
						const reader = new FileReader();
						reader.onload = (e) => {
							// 读取完成后，将图片的Data URL赋值给pastedImageUrl
							this.imageUrl = e.target.result;
							this.refresh()
						};
						// 以Data URL的形式读取文件
						reader.readAsDataURL(file);
						// 停止循环，因为我们只处理第一个图片文件
						break;
					}
				}
			}
		},
		onFileChange(e){
			const file = e.target.files[0];
			if (!file) return;

			// 创建一个FileReader实例
			const reader = new FileReader();

			// 读取文件为DataURL（Base64编码）
			reader.readAsDataURL(file);

			// 处理文件读取完成后的事件
			reader.onload = (e) => {
				this.imageUrl = e.target.result; // e.target.result 就是图片的Base64编码
				this.refresh()
			};

			// 错误处理
			reader.onerror = error => console.log('Error: ', error);
		},
		allPuhuo(){
			this.doAddAllProduct({data:{},success:(res) => {
				if(res.code == 200){
					this.$message({message: res.msg,type: 'success',});
				}else{
					this.$message({message: res.msg,type: 'error',});
				}
			}})
			
		},
		codeLoginClick(){
			this.login_type = 1
			setTimeout(()=>{this.loadingCode = true},100)
			this.getLoginCode('',true,'1')
		},
		accountLoginClick(){
			this.login_type = 2
			clearTimeout(this.checkBindTimer)
		},
		init(){
			this.getProduct()
		},
		getUCmenu(){
			this.getUcenterMenu({data:{},success:(res)=>{
				if(res.status==200){
					console.log('getUCmenu',res)
					// this.employeeData.data = res.data
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
		
		//获取商品列表
		getProduct(){
			if(this.product_loading) return
			this.product_loading = true
			this.list = []
			document.documentElement.scrollTop = 400
			let searchParams = this.searchParams
			let param = {limit:this.page_data.limit,page:this.page_data.page,image:this.imageUrl}
			Object.assign(param, searchParams)
			// console.log(param)
			this.doSearchProductByImage({data:param,success:(res)=>{
				if(res.code===200){
					// console.log('get goods list',res)
					// if(this.page_data.page == 1){
					// 	this.list = res.data.data
					// }else{
					// 	this.list = this.list.concat(res.data.data)
					// }
					this.list = res.data.data
					this.page_data.total = res.data.total
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
				this.product_loading = false
				// this.page_data.page ++
			}})
		},
		//滚动加载
		onScroll(){
			// 滚动条到最顶端的距离
			let scrolled = document.documentElement.scrollTop || document.body.scrollTop;
			let clientHeight = document.documentElement.clientHeight
			let scrollHeight = document.documentElement.scrollHeight
			if(scrolled + clientHeight + 400 >= scrollHeight){
				this.loadMore()
			}
		},
		//刷新列表
		refresh(){
			this.page_data.page = 1
			this.getProduct()
			this.getOrderCount()
		},
		//清空筛选
		reset(){
			this.searchParams = {}
			this.refresh()
		},
		//加载商品
		loadMore(){
			if (this.page_data.total <= this.list.length) {
				return false
			} else{
				this.getProduct()
			}
		},
		
		searchTap(name,val){
			if(!this.searchParams[name] || this.searchParams[name] != val){
				this.searchParams[name] = val
			}else{
				this.searchParams[name] = 0
			}
			this.refresh()
		},
		typeCommand(val){
			this.searchParams.name_type = val
			this.refresh()
		},
		priceCommand(val){
			this.searchParams.price_sort = val
			this.refresh()
		},
		timeCommand(val){
			this.searchParams.time_sort = val
			this.refresh()
		},
		supplierCommand(val){
			this.searchParams.supplier_user_id = val
			this.refresh()
		},
		
		
	}
}
</script>

<style scoped>
.image-uploader{position: absolute;left: 0;top: 0;width: 100%;height: 100%;opacity: 0;}
.search-image{width: 140px;height: 140px;object-fit: contain;margin: 20px auto;display: block;}
.register_main{width: 100%;height: auto;box-sizing: border-box;background: #fff;padding: 0 22px 30px;}
.register_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.register_main .title{line-height: 1;font-size: 20px;margin-bottom: 16px;}
.register_main .in_box{width: 100%;height: 38px;box-sizing: border-box;border: 1px solid #ddd;border-radius: 4px;display: flex;margin-top: 14px;align-items: center;}
.register_main .in_box .txt{margin-left: 10px;width: 70px;font-size: 14px;color: #999;}
.register_main .in_box .in{flex: 1;width: 0;margin-right: 14px;font-size: 14px;color: #333333;}
.register_main .in_box .get_code{font-size: 14px;color: #333333;padding: 0 12px;border-left: 1px solid #ccc;line-height: 1;cursor: pointer;}
.register_main .register_btn{width: 100%;height: 38px;border-radius: 4px;background: #fb5758;display: flex;justify-content: center;align-items: center;color: #ffffff;font-size: 14px;cursor: pointer;margin-top: 35px;}
.register_main .check_line{display: flex;align-items: center;font-size: 14px;margin-top: 14px;}
.register_main .code{display: block;width: 155px;height: 155px;margin: 16px auto 0;}
.register_main .code_txt{margin-top: 28px;text-align: center;font-size: 14px;color: #666666;}
.register_main .check_line .link{color: #fb5758;cursor: pointer;}
.login_main{width: 100%;height: auto;box-sizing: border-box;background: #fff;padding: 36px 0 28px;}
.login_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.login_main .login_type{padding: 14px 0;border-bottom: 1px solid #eee;display: flex;line-height: 1;}
.login_main .login_type .tab{flex: 1;text-align: center;font-size: 18px;cursor: pointer;}
.login_main .login_type .tab.active{font-weight: bold;color: #fb5758;}
.login_main .code_login{margin: 38px 0 55px;display: flex;flex-direction: column;align-items: center;}
.login_main .code_login .code{width: 155px;height: 155px;}
.login_main .code_login .txt{margin-top: 24px;line-height: 1;font-size: 14px;color: #666666;text-align: center;}
.login_main .account_login{margin: 0 22px 55px;padding-top: 20px;}
.login_main .account_login .in_box{width: 100%;height: 38px;box-sizing: border-box;border: 1px solid #ddd;border-radius: 4px;display: flex;margin-top: 14px;align-items: center;}
.login_main .account_login .in_box .icons{width: 38px;height: 100%;border-right: 1px solid #ddd;background: #f5f5f5;display: flex;justify-content: center;align-items: center;}
.login_main .account_login .in_box .icons .iconfont{font-size: 20px;color: #cccccc;}
.login_main .account_login .in_box .in{flex: 1;width: 0;margin: 0 14px;font-size: 14px;color: #333333;}
.login_main .account_login .in_box .txt{margin-left: 10px;width: 60px;font-size: 14px;color: #999;}
.login_main .account_login .in_box .get_code{font-size: 14px;color: #666;padding: 0 12px;border-left: 1px solid #ccc;line-height: 1;cursor: pointer;}
.login_main .account_login .change_way{margin-top: 20px;line-height: 1;display: flex;justify-content: space-between;}
.login_main .account_login .change_way>span{font-size: 14px;color: #333333;cursor: pointer;}
.login_main .account_login .login_btn{width: 100%;height: 38px;border-radius: 4px;background: #fb5758;display: flex;justify-content: center;align-items: center;color: #ffffff;font-size: 14px;cursor: pointer;margin-top: 35px;}
.login_main .go_register{margin: 0 30px;text-align: right;font-size: 14px;line-height: 1;}
.login_main .go_register>a{color: #fb5758;text-decoration: underline;cursor: pointer;}
.home_main{width: 100%;height: auto;margin-top: 30px;margin-bottom: 40px;}
.search_wrap{margin: 0 auto;display: flex;justify-content: center;font-size: 16px;}
.search_wrap .search_box{width: 900px;height: 50px;box-sizing: border-box;background-color: #ffffff;border-radius: 4px 0vw 0vw 4px;border: solid 2px #fa5757;display: flex;align-items: center;position: relative;}
.search_wrap .search_box .type{width: 84px;color: #333333;cursor: pointer;text-align: center;line-height: 26px;border-right: 1px solid #ddd;}
.search_wrap .search_box .in{flex: 1;width: 0;margin: 0 15px;color: #333333;font-size: 16px;}
.search_wrap .search_box .el-icon-search{margin-left: 14px;font-size: 20px;color: #999;cursor: pointer;}
.search_wrap .search_box .icon-xiangji{margin-right: 14px;font-size: 24px;color: #999;cursor: pointer;position: relative;}
.search_wrap .search_btn{width: 132px;height: 50px;background-image: linear-gradient(180deg, #fa5757 0%, #fb6d6d 100%), linear-gradient(#ffffff, #ffffff);background-blend-mode: normal, normal;border-radius: 0vw 4px 4px 0vw;display: flex;justify-content: center;align-items: center;color: #ffffff;font-weight: bold;cursor: pointer;}
.top_section{margin-top: 30px;width: 100%;display: flex;justify-content: space-between;}
.top_section .left_type{width: 220px;height: 384px;background-color: #ffffff;box-sizing: border-box;padding-bottom: 12px;padding-top: 24px;position: relative;}
.left_type .type_show{width: 100%;height: 100%;overflow-y: scroll;}
.left_type .type_show .title{display: flex;align-items: center;line-height: 1;font-weight: bold;margin-bottom: 10px;padding: 0 20px;}
.left_type .type_show .title .icon-fenlei2{font-size: 16px;margin-right: 8px;}
.left_type .type_show .type_line{width: 100%;min-height: 32px;display: flex;align-items: flex-start;line-height: 32px;padding: 0 20px;box-sizing: border-box;font-size: 14px;}

.left_type .type_show::-webkit-scrollbar{width:5px;height:5px;}
.left_type .type_show::-webkit-scrollbar-track{background: #efefef;}
.left_type .type_show::-webkit-scrollbar-thumb{background: #bfbfbf;}
.left_type .type_show::-webkit-scrollbar-corner{background: #333;}

.left_type .type_show .type_line .iconfont{font-size: 18px;display: none;}
.left_type .type_show .type_line .con{flex: 1;width: 0;margin-left: 10px;}
.left_type .type_show .type_line .con>span:hover{color: #fa5757;text-decoration: underline;cursor: pointer;}
.left_type .type_show .type_line .con>em{font-weight: bold;padding: 0 6px;}
.left_type .type_show .type_line .con>em:last-child{display: none;}
.left_type .type_show .type_line:hover{background: #ffedee;}
.left_type .type_show .type_line.hover{background: #ffedee;}
.left_type .type_show .type_line.hover .con>span{color: #fa5757;text-decoration: underline;cursor: pointer;}
.left_type .detail_hide{width: 980px;height: auto;box-sizing: border-box;position: absolute;top: 0;left: 220px;z-index: 22;background: #fff;box-shadow: 0 0 10px 0 #eeeeee;visibility: hidden;opacity: 0;transition: all .3s ease-in-out;}
.left_type .detail_hide.active{visibility: visible;opacity: 1;}
.detail_hide .detail_label{height: auto;min-height: 300px;box-sizing: border-box;padding: 30px 30px 40px;}
.detail_hide .detail_label:first-child{margin-top: 0;}
.detail_hide .detail_label .lv1{color: #e4393c;font-weight: bold;line-height: 1;margin-bottom: 6px;font-size: 16px !important;}
.detail_hide .detail_label .label_line{margin-top: 12px;font-size: 12px;line-height: 24px;display: flex;}
.detail_hide .detail_label .label_line .tab_lv2{font-weight: bold;width: 90px;padding-right: 20px;}
.detail_hide .detail_label .label_line .tab_lv2 .lv2{cursor: pointer;}
.detail_hide .detail_label .label_line .tabs_wrap{flex: 1;width: 0;display: flex;flex-wrap: wrap;}
.detail_hide .detail_label .label_line .tabs_wrap .tab_lv3{margin-right: 15px;cursor: pointer;line-height: 1.9;}
.label_line .tab_lv2 .lv2:hover ,.label_line .tabs_wrap .tab_lv3:hover{color: #e4393c;}
.top_section .center_main{width: 600px;display: flex;flex-direction: column;justify-content: space-between;}
.center_main .swiper_wrap{width: 100%;height: 256px;}
.center_main .swiper_wrap .swiper{height: 100%;}
.center_main .swiper_wrap .swiper_btn{width: 26px;height: 50px;background: rgba(0, 0, 0, .4);display: flex;justify-content: center;align-items: center;cursor: pointer;position: absolute;top: 50%;transform: translateY(-50%);z-index: 22;}
.center_main .swiper_wrap .swiper_btn.prev{left: 0;}
.center_main .swiper_wrap .swiper_btn.next{right: 0;}
.center_main .swiper_wrap .swiper_btn .icon-fanhui{font-size: 20px;color: #ffffff;}
.center_main .swiper_wrap .swiper_btn:hover .icon-fanhui{color: #fa5757;}
.center_main .swiper_wrap .swiper_btn.next .icon-fanhui{transform: rotate(180deg);}
.center_main .adx_box{width: 100%;height: 112px;display: block;}
.top_section .right_con{width: 348px;display: flex;flex-direction: column;background: #fff;position: relative;}
.right_con .supplier_login_btn{position: absolute;right: 16px;top: 18px;font-size: 14px;color: #999 !important;}
.right_con .supplier_login_btn:hover{color: var(--red) !important;}
.right_con .user_wrap{padding: 28px 16px 26px;background: #faedee;}
.right_con .user_wrap .user_con{display: flex;align-items: center;}
.right_con .user_wrap .user_con .head_box{width: 54px;height: 54px;background: #ffffff;border-radius: 50%;display: flex;justify-content: center;align-items: center;position: relative;}
.right_con .user_wrap .user_con .head_box .iconfont{font-size: 34px;color: #fdaeae;}
.right_con .user_wrap .user_con .head_box .head{width: 100%;height: 100%;object-fit: cover;border-radius: 50%;}
.right_con .user_wrap .user_con .con{flex: 1;width: 0;margin-left: 15px;font-size: 14px;line-height: 1;}
.right_con .user_wrap .user_con .con .txt{margin-top: 8px;color: #999;}
.right_con .user_wrap .btn_wrap{margin-top: 18px;display: flex;justify-content: space-between;}
.right_con .user_wrap .btn_wrap .btn{width: 152px;height: 32px;box-sizing: border-box;border-radius: 5em;display: flex;justify-content: center;align-items: center;font-size: 14px;cursor: pointer;}
.right_con .user_wrap .btn_wrap .btn:nth-child(1){background: #fa5757;color: #ffffff;}
.right_con .user_wrap .btn_wrap .btn:nth-child(2){background: #fff;color: #fa5757;border: 1px solid #fa5757;}
.right_con .order_nums{width: 100%;height: 88px;border-bottom: 1px solid #eeeeee;display: flex;align-items: center;}
.right_con .order_nums .num_box{flex: 1;text-align: center;line-height: 1;cursor: pointer;}
.right_con .order_nums .num_box .num{font-size: 20px;}
.right_con .order_nums .num_box .txt{font-size: 14px;color: #666666;margin-top: 10px;}
.right_con .news_wrap{flex: 1;height: 0;padding: 18px 16px 0;}
.right_con .news_wrap .news_img{width: 73px;height: 18px;margin-bottom: 4px;}
.right_con .news_wrap .news_link{margin-top: 12px;font-size: 14px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;display: block;color: #333333;line-height: 1;cursor: pointer;}
.right_con .news_wrap .news_link:hover{color: #fa5757;text-decoration: underline;}
.product_section{width: 100%;height: auto;box-sizing: border-box;background: #fff;margin-top: 20px;padding: 0 20px 20px;}
.product_section .screen_wrap{width: 100%;height: 47px;border-bottom: 1px solid #eeeeee;display: flex;align-items: center;}
.screen_wrap .title{font-weight: bold;margin-right: 60px;}
.screen_wrap .title span{color: #fa5757;}
.screen_wrap .tab{font-size: 14px;margin-right: 40px;display: flex;align-items: center;cursor: pointer;}
.screen_wrap .tab:hover{color: var(--red);text-decoration: underline;}
.screen_wrap .tab.active{color: #fa5757;}
.screen_wrap .in_box{width: 54px;height: 24px;border: 1px solid #ccc;box-sizing: border-box;padding: 0 6px;display: flex;align-items: center;font-size: 14px;color: #999;}
.screen_wrap .in_box .in{margin-left: 6px;}
.screen_wrap .in_line{width: 20px;display: flex;justify-content: center;align-items: center;color: #999999;}
.screen_wrap .in_btn{margin-left: 12px;width: auto;padding:0 10px;height: 26px;background: #ddd;display: flex;justify-content: center;align-items: center;cursor: pointer;font-size: 12px;color: #666666;}
.el_input{margin: 14px auto 0 !important;width: 100%;}
</style>
<style>
.register_mask .el-dialog__body,.login_mask .el-dialog__body,.login_mask .el-dialog__header{padding: 0;}
.el_input.el_in2 .el-input__prefix{top: 0;left: 0;}
.el_input{display: flex;margin-top: 14px;}
.el_input .el-input__inner{height: 38px;line-height: 38px;padding-left: 58px;flex: 1;width: 0;}
.el_input .el-input__inner:focus{border-color: var(--red);}
.el_input.el_in2 .el-input__inner{padding-left: 90px;}
.account_login .el_input .el-input__prefix{left: 1px;top: 1px;height: 36px;}
.el_input .el-input__prefix .icons{width: 38px;height: 36px;display: flex;justify-content: center;align-items: center;top: 1px;left: 2px;background: #f5f5f5;border-right: 1px solid #ddd;border-radius: 4px 0 0 4px;}
.el_input .el-input__prefix .icons .iconfont{font-size: 20px;color: #cccccc;}
.el_input .el-input__prefix .txts{width: 100%;height: 100%;display: flex;align-items: center;padding-left: 10px;}
.el_input .el-input__suffix{display: flex;}
.el_input .el-input__suffix .el-input__suffix-inner{display: flex;align-items: center;}
.el_input .el-input__suffix .el-input__suffix-inner .getcode{font-size: 14px;color: #333333;padding: 0 12px;border-left: 1px solid #ccc;line-height: 1;cursor: pointer;}
.register_main .check_line .el-checkbox{margin-right: 8px;margin-top: 2px;}
.el-checkbox__input.is-focus .el-checkbox__inner{border-color: #DCDFE6 !important;}
.el-checkbox__input.is-checked .el-checkbox__inner{background-color: #fb5758 !important;border-color: #fb5758 !important;}
.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover{background-color: #ffedee;color: #fa5757;}
</style>